export const environment = {
  production: true,
  environmentName:'aboSamra',
  allowHeadder:true,
  url:{
     admin:'https://adminapi.travasky.com',
     prepay:'https://prepayapi.travasky.com'
    },
  Apis:
    {
     getCharges:'/api/PaymentCharge/Get',
     getflight:'/api/GetDataOnLoading',
     startPayment:'/api/startpaymentProcess',
  },
  gates:[
    {
      PaymentMethod:'fss',
      cardImg:'../../../assets/cards/visamaster.png',
      GatewayType:'FSSCard'
    },
    {
      PaymentMethod:'fss',
      cardImg:'../../../assets/cards/checkout_masterdebit.png',
      GatewayType:'FSSMasterDebit'
    },
    {
      PaymentMethod:'fss',
      cardImg:'../../../assets/cards/checkout_mastercredit.png',
      GatewayType:'FSSMasterCredit'
    },
    {
      PaymentMethod:'fss',
      cardImg:'../../../assets/cards/checkout_visadebit.png',
      GatewayType:'FSSVisaDebit'
    },
    {
      PaymentMethod:'fss',
      cardImg:'../../../assets/cards/checkout_visacredit.png',
      GatewayType:'FSSVisaCredit'
    },
    {
      PaymentMethod:'HostedKnet',
      cardImg:'../../../assets/cards/KNETL.svg',
      GatewayType:'HostedKnet'
    },
    {
      PaymentMethod:'knet',
      cardImg:'../../../assets/cards/KNETL.svg',
      GatewayType:'Knet'
    },
    {
      PaymentMethod:'mada',
      cardImg:'../../../assets/cards/mada.png',
      GatewayType:'Mada'
    },
    {
      PaymentMethod:'cards',
      cardImg:'../../../assets/cards/checkout_masterdebit.png',
      GatewayType:'TapMasterDebit'
    },
    {
      PaymentMethod:'cards',
      cardImg:'../../../assets/cards/checkout_mastercredit.png',
      GatewayType:'TapMasterCredit'
    },
    {
      PaymentMethod:'cards',
      cardImg:'../../../assets/cards/checkout_visadebit.png',
      GatewayType:'TapVisaDebit'
    },
    {
      PaymentMethod:'cards',
      cardImg:'../../../assets/cards/checkout_visacredit.png',
      GatewayType:'TapVisaCredit'
    },
    {
      PaymentMethod:'PnetCC',
      cardImg:'../../../assets/cards/visamaster.png',
      GatewayType:'PnetCard'
    },
    {
      PaymentMethod:'PnetCC',
      cardImg:'../../../assets/cards/checkout_masterdebit.png',
      GatewayType:'PnetMasterDebit'
    },
    {
      PaymentMethod:'PnetCC',
      cardImg:'../../../assets/cards/checkout_mastercredit.png',
      GatewayType:'PnetMasterCredit'
    },
    {
      PaymentMethod:'PnetCC',
      cardImg:'../../../assets/cards/checkout_visadebit.png',
      GatewayType:'PnetVisaDebit'
    },
    {
      PaymentMethod:'PnetCC',
      cardImg:'../../../assets/cards/checkout_visacredit.png',
      GatewayType:'PnetVisaCredit'
    },
    {
      PaymentMethod:'pnetKnet',
      cardImg:'../../../assets/cards/KNETL.svg',
      GatewayType:'PnetKnet'
    },
    {
      PaymentMethod:'myfatoorahcc',
      cardImg:'../../../assets/cards/visamaster.png',
      GatewayType:'MyFatoorahCard'
    },
    {
      PaymentMethod:'myfatoorahcc',
      cardImg:'../../../assets/cards/checkout_masterdebit.png',
      GatewayType:'MyFatoorahMasterDebit'
    },
    {
      PaymentMethod:'myfatoorahcc',
      cardImg:'../../../assets/cards/checkout_mastercredit.png',
      GatewayType:'MyFatoorahMasterCredit'
    },
    {
      PaymentMethod:'myfatoorahcc',
      cardImg:'../../../assets/cards/checkout_visadebit.png',
      GatewayType:'MyFatoorahVisaDebit'
    },
    {
      PaymentMethod:'myfatoorahcc',
      cardImg:'../../../assets/cards/checkout_visacredit.png',
      GatewayType:'MyFatoorahVisaCredit'
    },
    {
      PaymentMethod:'MyFatoorahKnet',
      cardImg:'../../../assets/cards/KNETL.svg',
      GatewayType:'MyFatoorahKnet'
    },
    {
      PaymentMethod:'dafa3nycc',
      cardImg:'../../../assets/cards/visamaster.png',
      GatewayType:'Dafa3nyCC'
    },
    {
      PaymentMethod:'dafa3nycc',
      cardImg:'../../../assets/cards/checkout_masterdebit.png',
      GatewayType:'dafa3nymasterdebit'
    },
    {
      PaymentMethod:'dafa3nycc',
      cardImg:'../../../assets/cards/checkout_mastercredit.png',
      GatewayType:'dafa3nymastercredit'
    },
    {
      PaymentMethod:'dafa3nycc',
      cardImg:'../../../assets/cards/checkout_visadebit.png',
      GatewayType:'dafa3nyvisadebit'
    },
    {
      PaymentMethod:'dafa3nycc',
      cardImg:'../../../assets/cards/checkout_visacredit.png',
      GatewayType:'dafa3nyvisaCredit'
    },
 
  ],
  terms:'https://travasky.com/termsOfuse',
  origin:'https://travasky.com',
  brand:'travaSky',
  brandLogo:'../../../assets/brands/travaSky-logo.png',
  carlogo:'../../../assets/brands/travaSky-logo.png'
};